export const SHORTS_DURATION = 40;
export const MOVIES_AT_BIG_SCREEN_BY_DEFAULT = 12;
export const MOVIES_AT_BIG_SCREEN_INCREMENT = 3;
export const MOVIES_AT_MIDDLE_SCREEN_BY_DEFAULT = 8;
export const MOVIES_AT_MIDDLE_SCREEN_INCREMENT = 2;
export const MOVIES_AT_SMALL_SCREEN_BY_DEFAULT = 5;
export const MOVIES_AT_SMALL_SCREEN_INCREMENT = 2;
export const MIDDLE_SCREEN_WIDTH = 768;
export const SMALL_SCREEN_WIDTH = 480;
export const RESIZE_LISTENER_TIMEOUT = 200;
